<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>Special Materials</h3>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-form v-model="valid" class="mt-2 mb-0">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="selectedMaterial"
                            hide-details
                            label="ITEM"
                            prepend-icon="mdi-barcode"
                            :items="items"
                            item-text="codeAndDescription"
                            :filter="filterItems"
                            :rules="[rules.required]"
                            required
                        />
                    </v-col>
                </v-row>
                <v-row cols="12">
                    <v-col>
                        <v-textarea
                            v-model="notes"
                            prepend-icon="mdi-text"
                            label="Notas"
                            rows="1"
                            class="mx-3"
                            hide-details
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                color="primary"
                class="mb-4"
                @click="saveMaterial()"
                rounded
                :loading="loading"
                :disabled="!valid"
            >
                SAVE
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import { objDiff } from '@/helpers/objDiff.js'
import _ from 'lodash'

export default {
    name: 'ProcessForm',
    props: {
        originalMaterial: {
            type: Object,
            default: () => ({}),
        },
        createForm: {
            type: Boolean,
            default: () => false,
        },
        settingId: {
            type: String,
            default: () => '',
        },
        items: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        loading: false,
        valid: false,
        rules: {
            required: v => !!v || 'The value is required',
        },
        selectedItem: {},
        selectedMaterial: {},
        notes: '',
        material: {},
    }),
    computed: {
        processDiff: function() {
            if (!this.createForm) {
                return objDiff(this.originalMaterial, this.material)
            } else {
                return null
            }
        },
    },
    async mounted() {
        this.selectedMaterial = {
            name: 'Jorge',
            codeAndDescription: 'UnCodeAndDescription',
        }
        if (this.originalMaterial) {
            this.material = _.cloneDeep(this.originalMaterial)
        }
        if (this.createForm == false) {
            this.selectedItem = {
                codeAndDescription: `(${this.material.code}) ${this.material.description}`,
                material: this.material.code,
                description: this.material.description,
                notes: this.notes,
            }
        } else {
            this.selectedMaterial = {
                codeAndDescription: '',
                material: '',
                notes: '',
            }
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        filterItems(item, queryText, itemText) {
            return (
                itemText
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1 ||
                `${item.material}`
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1
            )
        },
        async saveMaterial() {
            let start = this.selectedMaterial.indexOf('(') + 1
            let end = this.selectedMaterial.indexOf(')')
            this.selectedItem.material = Number(
                this.selectedMaterial.substring(start, end)
            )
            this.selectedItem.short_material_text = this.selectedMaterial.substring(
                end + 2
            )
            this.selectedItem.codeAndDescription = this.selectedMaterial
            this.selectedItem.notes = this.notes
            if (this.createForm) {
                await this.create()
            } else {
                await this.update()
            }
        },
        async create() {
            try {
                this.loading = true
                this.selectedItem.settingId = this.settingId
                const response = await API.createSpecialMaterial(
                    this.selectedItem
                )
                this.$emit('addMaterial', response)
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async update() {
            try {
                this.loading = true
                const material = await API.updateSpecialMaterial({
                    settingId: this.settingId,
                    materialId: this.material.id,
                    ...this.processDiff,
                })
                this.$emit('replacematerial', material)
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
        selectModel() {
            if (this.createForm) {
                return
            }
        },
        selectItem() {},
    },
}
</script>

<style></style>
